// import './App.css';
import React from 'react';
import Home from './components/Home';
import About from './components/About';
import Menu from './components/Menu';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

export default function App() {
  return (
    <Router>
      <div>
        <Menu />
        <main>
          <section>
            <div className="container">
              <Switch>
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </div>
          </section>
        </main>
      </div>
    </Router>
  );
}
