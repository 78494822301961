import React from 'react';

export default function About() {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Joe Jobs</h5>
        <h6 className="card-subtitle mb-2 text-muted">
          jobs@365retailmarkets.com
        </h6>
        <p className="card-text">Stay Thirsty My Friends!</p>
      </div>
    </div>
  );
}
